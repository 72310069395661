/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TaskChart = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 22">
    <path
      fillRule="evenodd"
      d="M16 2h-4.2C11.4.8 10.3 0 9 0 7.7 0 6.6.8 6.2 2H2C.9 2 0 2.9 0 4v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm7 18H2V4h2v3h10V4h2v16z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default TaskChart;
