/* eslint-disable no-return-await */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import { toast } from 'react-toastify';
import { isAuthenticatedSelector, isLockedSelector, getBearerTokenSelector } from 'redux-core/auth/selectors';
import { setLocked, logOut, addInterceptedRequest } from 'redux-core/auth/actions';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import omit from 'ramda/src/omit';
import always from 'ramda/src/always';
import mergeAll from 'ramda/src/mergeAll';
import { showSnackbar, showGenericError } from 'redux-core/global-error/actions';
import i18next from 'i18next';
import { addPermissions } from 'redux-core/permissions/actions';
import { showErrorToast } from 'components/common/toastNotifications';
import { store } from '../redux-core/store';
import { getLocationSelector } from '../redux-core/router/selectors';

const GENERIC_ERROR_CODE = 'GENERIC_ERROR';

export const axiosInstanceConfig = {
  baseURL: '/api',
  headers: { originApp: 'qclear' },
};

export const requester = axios.create(axiosInstanceConfig);

export const makeCallWithConfig = (config) => {
  const bearerToken = getBearerTokenSelector(store.getState());
  config.baseURL = '';
  config.headers.Authorization = `Bearer ${bearerToken}`;
  return requester(config);
};

function showI18nMessages(data) {
  const { messages } = data || {};
  if (messages) {
    messages.forEach(({ severity, description }) => {
      const showToast = toast[severity.toLowerCase()] || toast.info;
      showToast(description, {
        className: 'qw-toast',
      });
    });
  }
}

requester.interceptors.response.use(
  (response) => {
    const { data } = response;
    showI18nMessages(data);
    return permissionsMiddleware(response);
  },
  (error) => {
    const { response } = error;

    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 409) {
      const currentPath = getLocationSelector(store.getState());
      store.dispatch(
        showSnackbar({
          message: i18next.t('global.versionError'),
          linkText: i18next.t('global.refreshInformation'),
          snackbarLinkRoute: currentPath,
          snackbarProps: { autoHideDuration: null },
        }),
      );
      return Promise.reject(409);
    }

    /** If user is authenticated, it means they logged in at some point, not that their token is still valid */
    const authenticated = isAuthenticatedSelector(store.getState());
    if (response.status === 401 && authenticated) {
      if (response.config && !response.config._isRetry) {
        /** First attempt at an unauthorized call */
        response.config._isRetry = true;
        const locked = isLockedSelector(store.getState());

        /** Check if screen is already locked, if not open the LockedMessage modal */
        if (!locked) store.dispatch(setLocked(true));

        /**
         * Leave request pending until user logs back in. They are stored in
         * the sessionLock node of the reducer and later resolved by
         * AuthenticationActions -> refreshTokenOnLock. When resolved,
         * The failed call is attempted again and the action continues it's
         * normal course
         */

        return new Promise((resolve) => {
          store.dispatch(
            addInterceptedRequest({
              resolveRequest: () => resolve(makeCallWithConfig(response.config)),
            }),
          );
        });
      }
      /** If this was the second attempt at the same call, log user out completely, return default rejected promise */
      store.dispatch(logOut());
    }

    if (response.data?.messageCode === GENERIC_ERROR_CODE) {
      const message = response.data?.message;
      showErrorToast(message);
      return Promise.reject(message);
    }

    /** Default return of interceptor */
    return Promise.reject(error);
  },
);

const validateHeader = (value, header) => !isNil(value) && !isNaN(value) && { [header]: value };

export const postCallAPI = async ({ path, body = {} }) => {
  const bearerToken = getBearerTokenSelector(store.getState());
  return requester.post(path, omit(['tenantId', 'divisionId'])(body), {
    headers: {
      ...validateHeader(body.divisionId, 'divisionId'),
      ...validateHeader(body.tenantId, 'tenantId'),
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }),
    },
  });
};

export const genericPostCallRequest = async ({ url, ...params }) => {
  const { data } = await axios({
    ...params,
    method: 'post',
    url,
  });
  return data;
};

export const genericGetCallRequest = async ({ url, ...params }) => {
  const { data } = await axios({
    ...params,
    method: 'get',
    url,
  });
  return data;
};

export const genericPutCallRequest = async ({ path, data, headers }) => {
  const instance = axios.create();
  instance.defaults.headers.common = {};
  return await instance.put(path, data, {
    headers,
  });
};

const requestWithErrorDefaultSettings = {
  fallback: always(undefined),
  message: {},
};

export const requestWithError = async (request, params, settings) => {
  const { fallback, message } = mergeAll([requestWithErrorDefaultSettings, settings]);
  try {
    const result = await request(params);
    if (message.success) {
      store.dispatch(showSnackbar({ message: message.success }));
    }
    return result;
  } catch (error) {
    if (message.failed) {
      store.dispatch(showSnackbar({ message: message.failed }));
    } else if (error.response.data?.message) {
      store.dispatch(showSnackbar({ message: error.response.data.message }));
    } else {
      store.dispatch(showGenericError());
    }
    return fallback();
  }
};

const permissionsMiddleware = ({ data: response }) => {
  if (!response) return response;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { permissions, data, messages, ...rest } = response;
  store.dispatch(addPermissions(permissions));
  if (isEmpty(rest)) return data;
  return { data, ...rest };
};
