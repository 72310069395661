export const OptionsPropTypes = (props, propName, componentName) => {
  const valueKey = props.valueKey || 'id';
  const labelKey = props.labelKey || 'name';
  const options = props[propName];

  if (options.length && options[0]) {
    const option = options[0];

    if (!option[valueKey] && option[valueKey] !== 0) {
      throw new Error(
        `valueKey prop "${valueKey}" doesn't match with option ${JSON.stringify(option)} in component ${componentName}`,
      );
    }

    if (!option[labelKey] && option[labelKey] !== 0) {
      throw new Error(
        `labelKey prop "${labelKey}" doesn't match with option ${JSON.stringify(option)} in component ${componentName}`,
      );
    }
  }
};
