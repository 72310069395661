/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  create: createCueSheetTemplate,
  delete: deleteCueSheetTemplate,
  get: getCueSheetTemplate,
  getAll: getAllCueSheetTemplates,
  update: updateCueSheetTemplate,
} = makeEntityServiceCalls('templates', 'cue');

export const likeCueSheetTemplate = makeServiceCall('templates', 'like', 'cue');

export const importCueSheetCall = makeServiceCall('cue-sheet-imports', 'import', 'cue');

export const compareTracksImportCall = makeServiceCall('track-imports', 'compare', 'tracks');

export const comapareEdlCall = makeServiceCall('track-imports', 'compare-edl', 'tracks');
