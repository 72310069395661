/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useCallback } from 'react';
import { ADD_TAG_EVENT } from 'components/common/TemplateManager/TemplateCreator/Tabs/ComposeTemplate';
import { useEventEmitter, useEventListener, useScript, usePrevious } from 'utils/hooks';
import { Box } from '@material-ui/core';
import { ENV } from 'utils/constants';
import { EVENTS, CONTAINER_ID, addTag, toggleOverlay } from './utils';

function BaseOnlyOffice({
  disabled,
  document,
  documentType,
  editorConfig,
  events,
  height,
  onlyOfficeId,
  scriptUrl,
  token,
  type,
  width,
  onDisabled,
}) {
  const config = {
    document,
    documentType,
    editorConfig,
    events,
    height,
    token,
    type,
    width,
  };
  const ref = useRef();
  const prevUrl = usePrevious(document.url);
  const [loaded] = useScript(scriptUrl);
  const emitDownload = useEventEmitter(EVENTS.DOWNLOAD_AS);
  useEventListener(ADD_TAG_EVENT, addTag(onlyOfficeId));

  // Logic for obtaining downloadUrl
  const getDownloadURL = (ext) => ref.current && ref.current.downloadAs(ext);
  useEventListener(EVENTS.PROCESS_URL, ({ detail }) => getDownloadURL(detail));

  useEffect(() => {
    if (ref.current && prevUrl !== document.url) {
      ref.current.destroyEditor();
      ref.current = null;
    }
  }, [document.url, prevUrl]);

  const onDownloadAs = useCallback(
    (e) => {
      const downloadUrl =
        e.data.split(ENV.OO_HOST)[1] ||
        // Sometimes only office doesn't return the port in the e.data url
        // so after .split()[1] will be null. In that case, we do the same
        // but using the plain host url, without the port.
        e.data.split(process.env.REACT_APP_OO_HOST)[1];
      emitDownload(downloadUrl);
    },
    [emitDownload],
  );

  useEffect(
    () => {
      if (!(loaded && window.DocsAPI && document.url && !ref.current)) return;
      ref.current = new window.DocsAPI.DocEditor(onlyOfficeId, {
        ...config,
        events: { ...events, onDownloadAs },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document, loaded, onlyOfficeId],
  );

  useEffect(() => {
    toggleOverlay(disabled);

    if (onDisabled) {
      onDisabled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <Box id={CONTAINER_ID} height="100%" width={1} data-testid="onlyOfficeContainer">
      <div id={onlyOfficeId} />
    </Box>
  );
}

BaseOnlyOffice.defaultProps = {
  documentType: 'text',
  onlyOfficeId: 'onlyOfficeId',
  scriptUrl: ENV.OO_INCLUDE_URL,
  document: {},
  events: {},
};

export default BaseOnlyOffice;
