/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Text } from 'components/styleguide';

const Link = ({ to, children }) => (
  <RouterLink
    to={to}
    component={({ navigate }) => (
      <Text children={children} color="blue" component="a" onClick={navigate} testId="router-link" underlined />
    )}
  />
);

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      hash: PropTypes.string, // "#the-hash"
      pathname: PropTypes.string, // "/route"
      search: PropTypes.string, // "?sort=name"
      state: PropTypes.object,
    }),
  ]).isRequired,
};

export default Link;
