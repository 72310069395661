/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ManualIndicator = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <circle cx="8" cy="8" r="7.5" fill="#fff" stroke="#E66339" />
    <path
      fill="#E66339"
      d="M7.313 12l-1.89-6.16h-.049c.068 1.253.102 2.089.102 2.508V12H3.988V4.147h2.267l1.858 6.005h.033l1.97-6.005h2.267V12h-1.552V8.283c0-.175.002-.378.005-.607.008-.229.033-.838.076-1.826h-.049L8.838 12H7.313z"
    />
  </SvgIcon>
);

export default ManualIndicator;
