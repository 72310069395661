/* eslint-disable import/no-cycle */
import { postCallAPI } from 'utils/request';

export const makeServiceCall =
  (basePath, endpoint, app = 'common') =>
  (payload) =>
    postCallAPI({
      path: `/${app}/${basePath}/${endpoint}`,
      body: payload,
    });

const makeEntityServiceCalls = (basePath, app) => ({
  getAll: makeServiceCall(basePath, 'get-all', app),
  get: makeServiceCall(basePath, 'get', app),
  create: makeServiceCall(basePath, 'create', app),
  update: makeServiceCall(basePath, 'update', app),
  save: makeServiceCall(basePath, 'save', app),
  search: makeServiceCall(basePath, 'search', app),
  delete: makeServiceCall(basePath, 'delete', app),
  apply: makeServiceCall(basePath, 'apply', app),
});

export default makeEntityServiceCalls;
