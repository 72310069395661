/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OutOfSyncIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 18">
    <path
      fill="#2182CC"
      fillRule="evenodd"
      d="M3 9c0 2.2.9 4.2 2.4 5.6L3 17h6v-6l-2.2 2.2C5.7 12.2 5 10.7 5 9c0-2.6 1.7-4.8 4-5.7v-2c-3.4.9-6 4-6 7.7z"
      clipRule="evenodd"
    />
    <path fill="#5CB9FF" fillRule="evenodd" d="M11 14h2v-2h-2v2z" clipRule="evenodd" />
    <path
      fill="#2182CC"
      fillRule="evenodd"
      d="M21 1h-6v6l2.2-2.2C18.3 5.8 19 7.3 19 9c0 2.6-1.7 4.8-4 5.7v2.1c3.4-.9 6-4 6-7.7 0-2.2-.9-4.2-2.4-5.6L21 1z"
      clipRule="evenodd"
    />
    <path fill="#5CB9FF" fillRule="evenodd" d="M11 10h2V4h-2v6z" clipRule="evenodd" />
  </SvgIcon>
);

export default OutOfSyncIcon;
