/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isNil from 'ramda/src/isNil';
import Row from '../layout/Row';

const PANEL_ID = 'panel';

const CollapseAccordion = ({ children, defaultExpandedIndex, row, onChange, expanded }) => {
  const defaultExpanded = !isNil(defaultExpandedIndex) ? `${PANEL_ID}-${defaultExpandedIndex}` : undefined;

  const [expandedState, setExpanded] = useState(defaultExpanded);

  const handleChange = (panel) => (_event, isExpanded) => {
    const value = isExpanded ? panel : undefined;
    if (onChange) {
      onChange(value);
      return;
    }
    setExpanded(value);
  };

  const handleExpanded = (index) => {
    if (expanded) {
      return expanded === `${PANEL_ID}-${index}`;
    }
    return expandedState === `${PANEL_ID}-${index}`;
  };

  return React.Children.map(children, (child, index) => {
    const Child = React.cloneElement(child, {
      id: `${PANEL_ID}-${index}`,
      expanded: handleExpanded(index),
      onExpandChange: handleChange(`${PANEL_ID}-${index}`),
    });
    return row ? <Row {...row}>{Child}</Row> : Child;
  });
};

CollapseAccordion.propTypes = {
  children: PropTypes.any.isRequired,
  defaultExpandedIndex: PropTypes.number,
  onChange: PropTypes.func,
  expanded: PropTypes.string,
  /* <Row> props */
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default CollapseAccordion;
