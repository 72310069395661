/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AssetsIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 31">
    <path d="M4 3a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v27a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V3z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M26 15.5V12c0-.6-.4-1-1-1H13c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-3.5l4 4v-11l-4 4z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default AssetsIcon;
