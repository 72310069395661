/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CopyFee = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 30">
    <path
      fill="#009DFB"
      fillRule="evenodd"
      d="M19.09 5H8.728C7.773 5 7 5.773 7 6.727v12.091h1.727V6.728h10.364V5zm-6.908 3.455h9.5c.953 0 1.727.773 1.727 1.727v12.09c0 .954-.774 1.728-1.727 1.728h-9.5a1.727 1.727 0 01-1.728-1.727V10.182c0-.954.773-1.727 1.728-1.727zm9.5 1.727h-9.5v12.09h9.5v-12.09z"
      clipRule="evenodd"
    />
    <circle cx="25" cy="21" r="7" fill="#009DFB" />
    <path fill="#fff" d="M23.067 17.634L26.425 21l-3.358 3.366L24.1 25.4l4.4-4.4-4.4-4.4-1.034 1.034z" />
  </SvgIcon>
);

export default CopyFee;
