/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ScenesIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 31">
    <path fill="#fff" d="M4 0h29v11H4z" />
    <path
      fillRule="evenodd"
      d="M28 0l3.5 7h-5.25l-3.5-7h-3.5l3.5 7H17.5L14 0h-3.5L14 7H8.75l-3.5-7H3.5A3.51 3.51 0 0 0 0 3.5v24A3.51 3.51 0 0 0 3.5 31h28a3.51 3.51 0 0 0 3.5-3.5V0h-7z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default ScenesIcon;
