/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Export = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      fill="#009DFB"
      fillRule="evenodd"
      d="M18 20v-6h4l-7-7-7 7h4v6h6zM15 9.83L17.17 12H16v6h-2v-6h-1.17L15 9.83zM22 24v-2H8v2h14z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default Export;
