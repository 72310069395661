import { fetchFooterInfo, getSmartFlags } from 'redux-core/overview/actions';
import dayjs from 'dayjs';
import { baseApi } from 'services';
import { TClearance } from 'services/clear/clearances';
import { TLicensor } from 'services/clear/licensors';

export type TTrackUsage = {
  cueId: number;
  trackId: number;
  clearanceId: number;
  clearanceType: 'rights' | 'options';
  cueContainerId: number;
  cueContainerName: string;
  cueContainerType: string;
  cueContainerNumber?: string;
  currentCueContainerVersionName?: string;
  requestedUseType?: string;
  requestedUseDuration?: string;
  requestedUseDurationFull?: boolean;
  requestedUsageNotes?: string;
  actualUseType?: string;
  actualUseDuration?: string;
  actualUsageNotes?: string;
  actualUseDurationFull?: boolean;
};

export type TUpdateLicensorStatusDto = {
  id: number;
  clearanceStatusId: number;

  trackId: number;
};

export type TUpdateLicensorNotesDto = {
  id: number;
  notes: string;
};

export type TUpdateLicensorDatesDto = {
  id: number;
  quoteSentAt: Date | null;
  quoteReceivedAt: Date | null;
  quoteExpiresAt: Date | null;
  confirmationSentAt: Date | null;
  confirmationReceivedAt: Date | null;
  licenseSentAt: Date | null;
  licenseReceivedAt: Date | null;
  licenseRequestSentAt: Date | null;
  licenseRequestReceivedAt: Date | null;
  checkRequestSentAt: Date | null;
  checkRequestReceivedAt: Date | null;

  trackId?: number;
  bundleId?: number;
};

export type TClearanceBundle = {
  id: number;
  trackObjectId: number;
  trackId: number;
  divisionId: number;
  name: string;
  trackNotes?: string;
  usages: TTrackUsage[];
  clearances: TClearance[];
  licensors: TLicensor[];
  cueContainer?: any;
  preClearance: {
    inSync?:boolean
    active?: boolean;
    description?: string;
    type?: string;
    startDate?: Date;
    endDate?: Date;
    createdByName?: string;
    allProjects?: string
  };
};

export const clearancesBundleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    patchClearance: builder.mutation<Partial<TClearanceBundle>, Partial<TClearanceBundle>>({
      query: (body) => ({ url: 'clear/clearances-bundles/patch', method: 'POST', body }),
    }),
    updateBundleLicensor: builder.mutation<void, Partial<TUpdateLicensorNotesDto>>({
      query: (body) => ({ url: 'clear/clearances-bundles/update-licensor', method: 'POST', body }),
    }),
    updateLicensorsDates: builder.mutation<void, Partial<TUpdateLicensorDatesDto>>({
      query: (body) => ({ url: 'clear/clearances-bundles/update-licensor-dates', method: 'POST', body }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(getSmartFlags());
      },
    }),
    updateLicensorShare: builder.mutation<void, Partial<TUpdateLicensorDatesDto>>({
      query: (body) => ({ url: 'clear/clearances-bundles/update-licensor-share', method: 'POST', body }),
      invalidatesTags: (result, error, arg) => [{ type: 'clearanceBundle', id: arg.bundleId }],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(fetchFooterInfo(_.trackId));
        dispatch(getSmartFlags());
      },
    }),
    updateLicensorStatus: builder.mutation<void, Partial<TUpdateLicensorStatusDto>>({
      query: (body) => ({ url: 'clear/clearances-bundles/update-licensor-status', method: 'POST', body }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getTrackClearanceStatus', id: arg.trackId },
      ],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(getSmartFlags());
      },
    }),
    createClearanceBundle: builder.mutation<Partial<TClearanceBundle>, Partial<TClearanceBundle>>({
      query: (body) => ({ url: 'clear/clearances-bundles/create', method: 'POST', body }),
    }),
    toggleUsing: builder.mutation<void, { toggleUsing: { clearanceId: number; using: boolean }[] }>({
      query: (body) => ({ url: 'clear/clearances-bundles/toggle-using', method: 'POST', body }),
      invalidatesTags: ['getBundleLicenses', 'getTrackClearanceFees'],
    }),
    getClearanceBundles: builder.query<TClearanceBundle[], number>({
      query: (trackId) => ({ url: 'clear/clearances-bundles/get-all', method: 'POST', body: { trackId } }),
      keepUnusedDataFor: 0,
    }),
    getClearanceBundle: builder.query<TClearanceBundle, { bundleId: number; showInactiveClearances: boolean }>({
      query: ({ bundleId, showInactiveClearances }) => ({
        url: 'clear/clearances-bundles/get',
        method: 'POST',
        body: { bundleId, showInactiveClearances },
      }),
      transformResponse: (value: TClearanceBundle) => {
        if (!value) return null;
        return {
          ...value,
          clearances: value.clearances?.map((c) => ({
            ...c,
            qclearRights: c.qclearRights?.map((r) => ({
              ...r,
              start: r.start ? dayjs(r.start).toDate() : undefined,
              end: r.end ? dayjs(r.end).toDate() : undefined,
            })),
          })),
        };
      },
      providesTags: (result, error, arg) => result
        ? [({ type: 'clearanceBundle' as const, id: arg.bundleId }), 'getTrackClearanceStatus']
        : ['clearanceBundle'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateClearanceBundleMutation,
  usePatchClearanceMutation,
  useUpdateBundleLicensorMutation,
  useUpdateLicensorsDatesMutation,
  useUpdateLicensorShareMutation,
  useUpdateLicensorStatusMutation,
  useToggleUsingMutation,
  useGetClearanceBundleQuery,
  useGetClearanceBundlesQuery,
} = clearancesBundleApi;
