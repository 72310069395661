/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MenuIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 17 14">
    <path fillRule="evenodd" d="M0 10h17V8H0v2zm0 4h17v-2H0v2zm0-8h17V4H0v2zm0-6v2h17V0H0z" clipRule="evenodd" />
  </SvgIcon>
);

export default MenuIcon;
