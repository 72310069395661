/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CueIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 34">
    <path
      fillRule="evenodd"
      d="M3.27 0C1.47 0 0 1.53 0 3.4v27.2C0 32.47 1.471 34 3.27 34h23.46C28.53 34 30 32.47 30 30.6V10.2L20.192 0H3.27z"
      clipRule="evenodd"
    />
    <path fill="#fff" fillRule="evenodd" d="M7.5 13.563v7.874h5.25L19.313 28V7l-6.563 6.563H7.5z" clipRule="evenodd" />
  </SvgIcon>
);

export default CueIcon;
